import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 8-8-8 @ 70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`400m Run`}</p>
    <p>{`21-Deadlifts (225/155)`}</p>
    <p>{`42-Wall Balls (20/14)`}</p>
    <p>{`15-Deadlifts`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`9-Deadlifts`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`400M Run`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our monthly free workout so bring a friend! 8:00 &
9:00am at The Ville and 10:00 & 11:00am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      